import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import BlogCategories from '../../../components/BlogCategories';

const UnderstandingLandSurveys = () => (
  <Layout
    title="Articles about surveying and RPR (Real Property Report) requirements relating to municipal bylaws permits and related issues."
    description="Want to learn more about the bylaws in Calgary, Red Deer and Edmonton and how they effect an RPR / Real Property Reports? This a in depth blog style resource to help you understand the details of the many permit and by-law issues."
    keywords=""
  >
    <main>
      <BlogCategories />

      <h1>Category: Understanding Land Surveys</h1>
      <ul>
        <li>
          <Link to="/articles/what-is-a-land-survey">What Is a Land Survey</Link>
          <br />A land survey is a crucial process conducted by professional surveyors to accurately
          measure, map, and verify the boundaries of a property. This service is essential for real
          estate transactions, construction planning, and avoiding encroachment issues near property
          boundaries. Surveys confirm legal descriptions of property lines, ensuring compliance with
          zoning regulations and preventing potential disputes over ownership and minimizing the
          risk of legal issues.
        </li>
        <li>
          <Link to="/articles/land-surveyors-and-rprs">
            Land Surveyors and RPRs - What are they? An Outsider's Perspective
          </Link>
          <br />
          Discover the vital role of land surveyors and Real Property Reports (RPRs) in the dynamic
          world of real estate and construction. Gain insights into the precision and expertise
          behind property boundaries, mapping, and urban planning. Explore the hidden realm of
          measurements and boundary markings that shape our communities.
        </li>
      </ul>
    </main>
  </Layout>
);

export default UnderstandingLandSurveys;
